export default {

    namespaced: true,

    state() {
        return {
            keyword: ''
        }
    },

    mutations: {
        setKeyword(state, payload) {
            state.keyword = payload
        },
    },
}
import Vue from 'vue'
import Vuex from 'vuex'
import city from './modules/city'
import alert from './modules/alert'
import radar from './modules/radar'
import cloud from './modules/cloud'
import search from './modules/search'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    city,
    alert,
    radar,
    cloud,
    search,
  }
})

import { fetchRadar } from '@/api/weather'
export default {

    namespaced: true,

    state() {
        return {
            list: []
        }
    },

    mutations: {
        setList(state, payload) {
            state.list = payload
        },
    },

    getters: {
        current(state) {
            return state.list.length > 0 ? state.list[state.list.length - 1] : []
        },
    },

    actions: {
        getList({commit}) {
            fetchRadar().then(res => {
                if (Array.isArray(res)) {
                    commit('setList', res)
                }
            })
          }
    }
}
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './css/index.less'

import Card from './components/card'
import Padding from './components/padding'
import Container from './components/container'

import {
  Button, Menu, Layout, Icon,  Table, Tabs, Form,
  Row, Col, Alert, Input, Checkbox, Select, Modal, Descriptions,
  Space, Divider, Dropdown, Badge, Avatar, Tree, message,
  Result, Upload, Progress, Breadcrumb, Statistic, List,
  Steps, Radio, InputNumber, Tooltip, ConfigProvider, FormModel,
  Empty, Pagination, DatePicker, Tag, Timeline,
  Carousel, Transfer, Cascader, Skeleton, Slider,Spin
} from 'ant-design-vue'

// // 常用方法
// import encrypt from './utils/encrypt'
// import { copy } from './utils/clipboard'
// import { getPermission } from '@/utils/permission'
// import { imgCrop } from '@/utils/image'
// import { close } from '@/utils/cache'
// import { selectFilterOption, ratioFormatter } from '@/utils/antdv'

Vue.config.productionTip = false

Vue
  .use(Button).use(Menu).use(Layout).use(Icon).use(Table)
  .use(Tabs).use(Form).use(Row).use(Col).use(Alert).use(Input).use(Checkbox)
  .use(Select).use(Modal).use(Descriptions).use(Space).use(Divider).use(Dropdown)
  .use(Badge).use(Avatar).use(Tree).use(Result).use(Upload).use(Progress)
  .use(Breadcrumb).use(Statistic).use(List).use(Steps).use(Radio)
  .use(InputNumber).use(Tooltip).use(ConfigProvider).use(FormModel)
  .use(Empty).use(Pagination).use(DatePicker).use(Tag).use(Timeline)
  .use(Carousel).use(Transfer).use(Cascader).use(Skeleton).use(Slider)
  .use(Spin)

Vue.prototype.$message = message;
Vue.prototype.$confirm = Modal.confirm;
Vue.prototype.$error = Modal.error;

Vue.component('Card', Card)
Vue.component('Padding', Padding)
Vue.component('Container', Container)

// Vue.component('Pane', Pane) // 页面的头部面板
// Vue.component('PrivateButton', PrivateButton) // 页面内的权限按钮
// Vue.component('PermissionButton', PermissionButton) // 页面内的权限按钮
// Vue.component("Padding", Padding) // 页面常用的间距组件
// Vue.component('DataDictFinder', DataDictFinder) // 数据字典查找器
// Vue.component("Money", Money)

// Vue.prototype.$getPermission = getPermission;
// Vue.prototype.$encrypt = encrypt;
// Vue.prototype.$copy = copy;
// Vue.prototype.$imgCrop = imgCrop;
// Vue.prototype.$close = close
// Vue.prototype.$selectFilterOption = selectFilterOption;
// Vue.prototype.$ratioFormatter = ratioFormatter;

function date(val, fmt) {
  val = val || Date.parse(new Date()) / 1000;
  fmt = fmt || 'yyyy-MM-dd hh:mm:ss'
  var d = new Date()
  d.setTime(val * 1000)

  var o = {
    "M+": d.getMonth() + 1,                 //月份 
    "d+": d.getDate(),                    //日 
    "h+": d.getHours(),                   //小时 
    "m+": d.getMinutes(),                 //分 
    "s+": d.getSeconds(),                 //秒 
    "q+": Math.floor((d.getMonth() + 3) / 3), //季度 
    "S": d.getMilliseconds()             //毫秒 
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (d.getFullYear() + "").substr(4 - RegExp.$1.length))
  }
  for (var k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)))
    }
  }
  return fmt
}

Vue.filter('timeTrans', (value, fmt) => {
  value = parseInt(value)

  if (value == 0 || !value) return '-'

  if (fmt) {
    return date(value, fmt)
  } else {
    var d, d_minutes, d_hours, d_days
    var timeNow = parseInt(new Date().getTime() / 1000)
    d = timeNow - value;
    d_days = parseInt(d / 86400)
    d_hours = parseInt(d / 3600)
    d_minutes = parseInt(d / 60)

    if (d_days > 0 && d_days < 4) {
      return d_days + "天前";
    } else if (d_days <= 0 && d_hours > 0) {
      return d_hours + "小时前";
    } else if (d_hours <= 0 && d_minutes > 0) {
      return d_minutes + "分钟前";
    } else if (d < 60) {
      return "刚刚";
    } else {
      var s = new Date(value * 1000);
      return s.getFullYear() + "/" + (String( s.getMonth() + 1).padStart(2, '0')) + "/" + String( s.getDate()).padStart(2, '0')
    }
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

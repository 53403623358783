<template>
    <div class="container">
        <div class="result" v-if="loading">
            <a-spin size="large" tip="加载中..." />
        </div>
        <div class="result" v-else-if="empty">
            <a-empty />
        </div>
        <div v-else>
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        empty: {
            type: Boolean,
            default: false,
        }
    }
}
</script>

<style lang="less" scoped>
.result {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding: 120px;
}
</style>

import { fetchSatellite } from '@/api/weather'
export default {

    namespaced: true,

    state() {
        return {
            cloudList: []
        }
    },

    mutations: {
        setList(state, payload) {
            state.cloudList = payload
        },
    },

    getters: {
        currentCloud(state) {
            return state.cloudList.length > 0 ? state.cloudList[state.cloudList.length - 1] : []
        },
    },

    actions: {
        getCloudList({commit}) {
            fetchSatellite().then(res => {
                if (Array.isArray(res)) {
                    commit('setList', res)
                }
            })
          }
    }
}
<template>
    <div :style="{paddingTop: `${paddingTop}px`}"></div>
</template>

<script>
export default {
    data() {
        return {
            paddingTop: 12,
        }
    }
}
</script>
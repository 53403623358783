<template>
  <div>
    <a-config-provider :locale="zh_CN">
      <router-view />
    </a-config-provider>
  </div>
</template>


<script>
import zh_CN from "ant-design-vue/es/locale/zh_CN";

export default {
  data() {
    return {
      zh_CN: Object.freeze(zh_CN),
    };
  },
};
</script>
<template>
    <div class="card">
        <div class="between  header" :class="url ? 'clickable' : ''" @click="route">
            <div class="title">{{title}}</div>
            <div class="extra" v-if="extra">
                <slot name='extra'>
                    <i class="iconfont icon-arrow-right"></i>
                </slot>
            </div>
        </div>
        <div class="body">
            <slot />
        </div>
    </div>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
        },
        extra: {
            type: Boolean,
            default: true,
        },
        url: {
            type: String,
        }
    },
    methods: {
        route() {
            if (this.url) {
                this.$router.push(this.url)
            }
        }
    }
}
</script>

<style lang="less" scoped>
.card {
    background-color: #fff;
    .header {
        padding: 12px;
        .title {
            font-weight: bold;
        }
        .icon-arrow-right {
            font-size: 12px;
        }
    }
    .body {
        padding: 0 12px 12px;
    }
}
</style>
import { fetchList } from '@/api/alert'
export default {

    namespaced: true,

    state() {
        return {
            list: []
        }
    },

    mutations: {
        setList(state, payload) {
            state.list = payload
        },
    },

    getters: {
        provinceList(state) {
            return state.list.filter(item => item.city === '');
        },
        cityList(state) {
            return state.list.filter(item => item.city !== '');
        }
    },

    actions: {
        getList({commit}) {
            fetchList().then(res => {
              if (res && Array.isArray(res.warnInforList)) {
                commit('setList', res.warnInforList)
              }
            })
          }
    }
}
import axios from 'axios'

if (process.env.NODE_ENV === 'production') {
    let url = axios.defaults.baseURL
    if(url) {
        axios.defaults.baseURL = url.indexOf('http')>-1 ? 
        url : '/Api'
    } else {
        axios.defaults.baseURL = '/Api'
    }
    
}

const instance = axios.create({
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
})

instance.interceptors.response.use(function (response) {

    const { status, data = {} } = response;
    if (status === 200) {
        if (data.status !== 1) {
            console.error(data.info ? data.info : '异常错误')
        }
    }
    return data.info;

}, function (error) {

    const { data = {} } = error.response;
    console.error(data.info ? data.info : '异常错误')
    return Promise.reject(data.info)

})

export default instance;
import request from './request'

export function fetchWeather(params) {
    return request({
        // MOSHI TODO
        // url: '/meteor/getCityForecast',
        url:'http://adm.ac.cn/Api/Weather/getForcast7?token=njszy',
        params
    })
}

export function fetchStationLive(params) {
    return request({
        url: '/meteor/getStationLive',
        params
    })
}

export function fetchWeatherByLnglat({ lng, lat }) {
    //return request(`/meteor/getLocationForecast?lng=${lng}&lat=${lat}`)
    return request(`http://adm.ac.cn/Api/Weather/getForcast7?token=njszy&lng=${lng}&lat=${lat}`)
}

export function fetchRadar() {
    return request({
        // moshi todo
        //url: '/meteor/getRadar',
        url:'http://adm.ac.cn/Api/Weather/getRadar?token=njszy',
        params: {
            ch: 'huadong'
        }
    })
}

export function fetchSatellite() {
    return request({
        //url: '/meteor/getSatellite',
        url:'http://adm.ac.cn/Api/Weather/getSatellite?token=njszy',
        params: {
            ch: 'FY4A-true-color'
        }
    })
}

// 获取3小时天气
export function fetchHourWeather(params) {
    return request({
        url: '/meteor/getEcStationDay',
        params,
    })
}

// 获取江苏省所有地市天气
export function fetchJSCityWeather() {
    return request({
        url: '/meteor/getEcJsCity',
    })
}
// /api/meteor/getCityRadar 参数 province=jiang-su    city=nan-jing

export default {

    namespaced: true,

    state() {
        return {
            city: '南京',
            list: [{
                name: '南京',
                'lng': 118.78, 'lat': 32.04,
                'code': 3201, 'obtid': 58238,
                image: 'https://s.upapi.cn/2022/07/10/18a957c95054a3ded74c13646a9f1fe3/nanjing.jpg',
                pinyin: 'Nanjing',
            }, {
                name: '无锡', 'code': 3202, 'obtid': 58354, 'lng': 120.29, 'lat': 31.59, image: 'https://s.upapi.cn/2022/07/10/d7414836406543bd836256231159f165/wuxi.webp',
                pinyin: 'Wuxi',

            },
            {
                name: '苏州', 'code': 3205, 'obtid': 58349, 'lng': 120.62, 'lat': 31.32, image: 'https://s.upapi.cn/2022/07/10/5c8214db7a09a8868c6630495e55883a/suzhou.webp',
                pinyin: 'Suzhou',

            },
            {
                name: '常州', 'code': 3204, 'obtid': 58343, 'lng': 119.95, 'lat': 31.79,
                image: 'https://s.upapi.cn/2022/07/10/2f5f8a5b35496a7629ebdf3b67197d41/changzhou.jpeg',
                pinyin: 'Changzhou',

            },
            {
                name: '徐州', 'code': 3203, 'obtid': 58027, 'lng': 117.2, 'lat': 34.26, image: 'https://s.upapi.cn/2022/07/10/d7414836406543bd836256231159f165/xuzhou.webp',
                pinyin: 'Xuzhou',

            },
            {
                name: '泰州', 'code': 3212, 'obtid': 58246, 'lng': 119.9, 'lat': 32.49,
                image: 'https://s.upapi.cn/2022/07/10/d1b419ede272ed33b812c51067fe0dcc/taizhou.webp',
                pinyin: 'Taizhou',

            },
            {
                name: '扬州',

                pinyin: 'Yangzhou',
                'code': 3210, 'obtid': 58245, 'lng': 119.42, 'lat': 32.39, image: 'https://s.upapi.cn/2022/07/10/0e5cb368f7ff59ccad66442eb96b968f/yangzhou.webp'
            },
            {
                name: '淮安',
                pinyin: 'Huaian',

                'code': 3208, 'obtid': 58141, 'lng': 119.15, 'lat': 33.5, image: 'https://s.upapi.cn/2022/07/10/5c8214db7a09a8868c6630495e55883a/huaian.webp'
            },
            {
                name: '南通',
                pinyin: 'Nantong',

                'code': 3206, 'obtid': 58259, 'lng': 121.05, 'lat': 32.08, image: 'https://s.upapi.cn/2022/07/10/d7414836406543bd836256231159f165/nantong.webp'
            },
            {
                name: '连云港',
                pinyin: 'Lianyungang',

                'code': 3207, 'obtid': 58044, 'lng': 119.16, 'lat': 34.59, image: 'https://s.upapi.cn/2022/07/10/18a957c95054a3ded74c13646a9f1fe3/lianyungang.webp'
            },
            {
                name: '宿迁',
                pinyin: 'Suqian',
                'code': 3213, 'obtid': 58131, 'lng': 118.3, 'lat': 33.96, image: 'https://s.upapi.cn/2022/07/10/25a6936182d27038efe49613a80d5cf6/suqian.webp'
            },
            {
                name: '镇江',
                pinyin: 'Zhenjiang',

                'code': 3211, 'obtid': 58252, 'lng': 119.44, 'lat': 32.2, image: 'https://s.upapi.cn/2022/07/10/25a6936182d27038efe49613a80d5cf6/zhenjiang.webp'
            },
            {
                name: '盐城',
                pinyin: 'Yancheng',

                'code': 3209, 'obtid': 58154, 'lng': 120.13, 'lat': 33.38, image: 'https://s.upapi.cn/2022/07/10/d1b419ede272ed33b812c51067fe0dcc/yancheng.webp'
            }
            ]
        }
    },

    mutations: {
        setCity(state, payload) {
            state.city = payload
        },
    },
}
export default [

  {
    path: '/',
    component: () => import('@/layout'),

    children: [
      {
        path: '',
        component: () => import('@/views/home'),
      },
      {
        path: 'weather-news',
        component: () => import('@/views/weather-news'),
      },

      {
        path: 'weather-forecast',
        component: () => import('@/views/weather-forecast'),
      },
      {
        path: 'disaster',
        component: () => import('@/views/disaster'),
      },

      {
        path: 'environment',
        component: () => import('@/views/environment'),
      },
      {
        path: 'traffic',
        component: () => import('@/views/traffic'),
      },
      {
        path: 'travel',
        component: () => import('@/views/travel'),
      },
      {
        path: 'farming',
        component: () => import('@/views/farming'),
      },
      {
        path: 'major',
        component: () => import('@/views/major'),
      },

      {
        path: 'hot',
        component: () => import('@/views/hot'),
      },
      {
        path: "hot-detail",
        component: () => import('@/views/hot/detail')
      },
      {
        path: 'products',
        component: () => import('@/views/products'),
      },

      {
        // 人物故事列表页面
        path: 'character-story',
        component: () => import('@/views/character-story'),
      },

      {
        // 统一的新闻详情页面
        path: 'article-detail',
        component: () => import('@/views/article-detail'),
      },
      {
        // 统一的新闻分类页面
        path: 'article-category',
        component: () => import('@/views/article-category'),
      },
           {
        // 搜索结果页面
        path: 'search',
        component: () => import('@/views/search'),
      },

      {
        // 预警详情页面
        path: 'alert-detail',
        component: () => import('@/views/alert-detail'),
      },
      {
        // 雷达图详情页面
        path: 'radar-detail',
        component: () => import('@/views/radar-detail'),
      },
      {
        // 云图详情页面
        path: 'cloud-detail',
        component: () => import('@/views/cloud-detail'),
      },

      {
        path: '*',
        component: () => import('@/views/404'),
      }
    ]
  },
]